<template>
    <div class="main_container">
        <div v-if="!accessToken && !userData">
            <NavBar/>
        </div>

        <div class="billing_content" :class="{'billing_top': accessToken && userData}">
            <div class="billing_logo"></div>
            <div class="billing_title">
                Billing Policies
            </div>
            <div class="billing_sub-content">
                <div class="billing_sub-item">
                    <span>COMPANY: </span>
                    REZI LEADS INC. and its affiliated entities (“Company”, “us”, “our”, and “we”)
                </div>
                <div class="billing_sub-item">
                    Please also refer to the Rezi Leads, Inc. Terms of Service. This agreement sets forth the billing
                    terms and conditions that apply to your access and use of Rezi Leads’ services (the “Services”).
                </div>
            </div>
            <div class="billing_points">
                <ol>
                    <li>
                        <span>General. </span> After agreeing to your Subscription Agreement, the Services will commence
                        and all corresponding payments, as set forth in the Subscription Agreement, will be due and
                        owing, including a one-time onboarding fee to set up your Rezi Leads account (your “Account”).
                        All prices are payable in USD, and all payments are non-refundable.
                    </li>
                    <li>
                        <span>Payment Instrument. </span> All payments must be made using a valid credit or debit card
                        issued by American Express, MasterCard, Discover or Visa, which must be kept on file with your
                        Account (your “Payment Instrument”). Checks, cash, and electronic funds transfer are not
                        accepted methods of payment. You are required to keep your Payment Instrument details and
                        contact information current, which you can do by logging into your Account, clicking on
                        Settings, and then clicking on Payment Settings. By providing your Payment Instrument to Rezi
                        Leads, you certify that you are an authorized user of the card.
                    </li>
                    <li>
                        <span>Billing Cycle. </span> You authorize Rezi Leads to bill your Payment Instrument in advance
                        on a
                        periodic basis for the amounts set forth in your Subscription Agreement, and you agree to pay
                        any charges so incurred, until the end of the Subscription Plan term set forth in your
                        Subscription Agreement (the “Subscription Term”). Billing periods typically begin on the day of
                        the month in which your Account was activated (“Billing Date”). Payment Instruments are
                        automatically charged at the beginning of each billing period on the Billing Date. Invoices and
                        payment receipts are available upon request by contacting the Rezi Leads Billing Department at
                        support@rezileads.com <br/><br/>
                        Billing Date, or if your Payment Instrument is declined, your access to your Account will be
                        temporarily suspended until your balance is paid in full. Rezi Leads will attempt to charge the
                        Payment Instrument on file for 60 calendar days. If your Account remains delinquent for 60
                        calendar days, the Account will be cancelled and turned over to a third-party collection
                        service. You will not be able to recover any files until the Account is reinstated by paying off
                        any remaining balances.
                    </li>
                    <li>
                        <span>Advertising Budget. </span> By subscribing to Rezi Leads ad placing services, you
                        understand and agree that you are required to pay a monthly amount (the “Ad Budget”) to be used
                        by Rezi Leads to contract with certain third party providers to place internet advertising on
                        your behalf to generate leads. You understand and agree that your Ad Budget will be
                        automatically charged to your Payment Instrument at the beginning of each billing period on the
                        Billing Date and does not replace, reduce or otherwise impact any other charges listed in your
                        Subscription Agreement. Rezi Leads makes no promise or guarantee of lead volume, quality, or
                        conversion rates.
                        <br/><br/>
                        You may choose the amount of your Ad Budget, subject to minimum required amounts. If your Ad
                        Budget drops below the applicable minimum, you may be subject to certain penalties. You may not
                        cancel or pause your Ad Budget while your Account remains active, but you may adjust the amount
                        of your Ad Budget at any time by notifying Rezi Leads in writing at support@rezileads.com. The
                        adjustment will be effective in the billing cycle immediately after you provide the foregoing
                        written notice.
                    </li>
                    <li>
                        <span>Cancellation. </span> At the end of the Subscription Term, your Account will
                        automatically convert to a month-to-month plan at then-current pricing, unless you a) sign a new
                        Subscription Agreement, or b) send written notice of cancellation to support@rezileads.com no
                        less than 10 days prior to the end of the Subscription Term and receive an email response
                        acknowledging your cancellation request. In the event your Account converts to a month-to-month
                        plan, you may cancel at any time by sending written notice of cancellation to
                        support@rezileads.com, in which case, your Account will be closed on the last day of the billing
                        period in which you submitted the cancellation request. <br/><br/>
                        If you cancel your Account prior to the end of the Subscription Term, or if we cancel your
                        Account due to a violation of the Terms of Service, this Billing Policy, or the Privacy Policy,
                        no refunds will be issued, all outstanding payments will remain due, and we will continue
                        charging the monthly payments to your Payment Instrument for the remainder of the Subscription
                        Term. Alternatively, you may pay an early cancellation fee, plus any outstanding payments, in a
                        lump sum payment due immediately, in which case, your Account will be closed on the last day of
                        the billing period in which the early cancellation fee was paid. If we agreed to waive the
                        onboarding fee in exchange for your commitment to a longer Subscription Term, early cancellation
                        will result in the reinstatement of that fee.
                        <br/><br/>
                        All outstanding payments must be paid in full upon cancellation of an Account. All cancelled
                        Accounts with an outstanding balance may be automatically turned over to a third-party
                        collection service, subject to the fees, charges, and assessments set forth in Section 6 (Fees)
                        below. Data will be stored for no longer than 90 days post cancellation.
                    </li>
                    <li>
                        <span>Fees. </span> Rezi Leads does not anticipate that you will fail to pay for the Services on
                        a timely
                        basis, and we do not extend credit to our customers. In the event you fail to make full and
                        complete payment by the Billing Date, you may be subject to the following fees, charges and
                        assessments:
                        <ol class="list_circle">
                            <li>Any payments that are past due will incur an interest charge at a rate equal to the
                                lesser of (i) 1.5% per month or (ii) the maximum interest rate allowed by applicable
                                law.
                            </li>
                            <li>
                                Any payments that are 10 days or more past due will incur an interest charge at a rate
                                equal to the lesser of (i) 10% per month or (ii) the maximum amount allowed by
                                applicable law.
                            </li>
                            <li>
                                In the event Rezi Leads receives a chargeback from a credit card company or bank on
                                your behalf, Rezi Leads may assess you a $50.00 processing fee for each individual
                                chargeback.
                            </li>
                            <li>
                                o In the event Rezi Leads uses a third-party collection service or attorney to collect
                                money owed to us by you, Rezi Leads may assess you a $15.00 processing fee, and you will
                                be charged all reasonable costs and fees associated with such a collection, including,
                                but not limited to, any third-party collection service’s fees, reasonable attorneys’
                                fees, and arbitration or court costs.
                            </li>
                            Any fees, charges, or assessments resulting from late payment or nonpayment will be
                            considered liquidated damages intended to be a reasonable advance estimate of our costs
                            resulting from such late payment or nonpayment. These costs will be difficult to calculate
                            or to predict when we set such fees, charges and assessments, because we cannot know in
                            advance whether you will pay for the Services on a timely basis, if ever, and what costs we
                            will incur as a result of your late payment or nonpayment.
                        </ol>
                    </li>
                    <li>
                        <span>Billing Disputes. </span> To dispute any charge related to the Services, you must contact
                        Rezi Leads in writing at support@rezileads.com, or submit a ticket from your Account, within 30
                        days after the disputed charge is first incurred. You agree to refrain from disputing the charge
                        with your credit card company or bank for 30 days after reporting the dispute to Rezi Leads, to
                        allow Rezi Leads the opportunity to resolve the dispute. Should Rezi Leads receive a chargeback
                        from a credit card company or bank on your behalf before Rezi Leads has been given 30 days to
                        resolve the issue, Rezi Leads has the right to collect on the rendered services and any payments
                        associated with the dispute. <br/> <br/>
                        In the event Rezi Leads receives a chargeback from a credit card company or bank on your behalf,
                        Rezi Leads will immediately suspend the Account pending the outcome of the dispute. Regardless
                        of the outcome of the chargeback, Rezi Leads retains the right to collect on any rendered
                        services or payments outstanding, and the Account may be turned over to a third-party collection
                        service. <br/> <br/>
                        If technical problems caused by Rezi Leads, as determined in Rezi Leads’ sole reasonable
                        discretion, prevent or unreasonably delay delivery of the Services, your exclusive and sole
                        remedy is either replacement of the Services or refund of the last monthly payment.
                    </li>
                </ol>

            </div>

        </div>
        <div v-if="!accessToken && !userData">
            <Footer/>
        </div>

    </div>
</template>

<script>
    import NavBar from "../components/NavBar";
    import Footer from "../components/Footer";
    import {mapGetters} from "vuex";

    export default {
        name: "BillingPolicies",
        data() {
            return {}
        },
        components: {
            NavBar,
            Footer
        },
        computed: {
            ...mapGetters(['accessToken', 'userData'])
        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
    .main {
        &_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .billing {
        &_content {
            width: 100%;
            padding: 100px 0 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1640px;
            margin: 0 auto;
            &.billing_top {
                padding-top: 20px !important;
                & .billing_logo {
                    display: block;
                }
            }
        }
        &_logo {
            display: none;
            width: 240px;
            height: 60px;
            background: url("~@/assets/images/Logo.svg") no-repeat center;
            background-size: contain;
            margin-bottom: 5px;
        }

        &_title {
            padding: 30px 0;
            font-size: 36px;
            font-weight: 600;
            color: $darkBlue;
        }

        &_sub-content {
            padding: 15px 0;
        }

        &_sub-item {
            padding: 10px 0;
            font-size: 24px;

            & span {
                color: $main_blue;
                font-weight: 600;
                margin-right: 10px;
            }
        }

        &_points {
            padding: 10px 10px 40px;
            display: flex;
            justify-content: flex-start;
            width: 100%;

            ol {
                list-style-type: none;
                counter-reset: item;
                margin: 0;
                padding: 0;
            }

            & li {
                padding: 10px 0;
                display: table;
                counter-increment: item;
                margin-bottom: 0.6em;

                & ul {
                    margin-left: 15px;

                    & li {
                        list-style-type: circle;
                    }
                }

                & li {
                    margin: 0;

                    &:before {
                        content: counters(item, ".") " ";
                    }
                }

                &:before {
                    content: counters(item, ".") ". ";
                    display: table-cell;
                    padding-right: 0.6em;
                    color: $light_blue;
                    font-weight: 600;
                }

                & span {
                    color: $light_blue;
                    font-weight: 600;
                }

                ::v-deep .list_circle {
                    margin-left: 0;

                    li {
                        margin-left: 15px;

                        &:before {
                            list-style-type: circle !important;
                            content: 'o';
                        }

                    }
                }
            }
        }
    }
</style>